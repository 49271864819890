import { Survey } from "../../types/survey";

export const ROUTE = {
  USER_ME: "/user/me",
  ACCOUNT: "/account",
  SURVEY: "/survey",
  REPORT: "/report",
  DASHBOARD: "/dashboard",
  USER: "/user",
  SETTING: "/setting",
  VERIFY_EMAIL: "/verify-email",
};

export const PROTERATE_TOKEN = "proterateToken";
export const PROTERATE_REFRESH_TOKEN = "proterateRefreshToken";

export const SURVEY_DEFAULT: Survey = {
  _id: "",
  creator_id: {
    _id: "",
    name: "",
    email: "",
    role: "",
  },
  is_active: true,
  title: "",
  description: "",
  trigger_criteria: [
    {
      hookUrl: "",
      pattern: "",
    },
  ],
  question: [
    {
      question_id: [
        {
          _id: "",
          question_display: "",
          is_require: false,
          option: {
            option_choices: [""],
            _id: "",
          },
        },
      ],
      step: "",
    },
  ],
  display_option: "1",
};
