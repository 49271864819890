import {
  Box,
  Container,
  Step,
  StepButton,
  Stepper,
  Typography,
  Button,
  Snackbar,
  Grow,
  Switch,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSurveyBySurveyId, updateSurvey } from "../../../utils/apis/survey-api";
import { Survey } from "../../../types/survey";
import CreateNewSurveyStep from "./steps/CreateSurvey";
import { useParams } from "react-router-dom";
import EditSurveyStep from "./steps/EditSurvey";
import TriggerSurvey from "./steps/TriggerSurvey";
import ReportSummary from "../report/components/ReportSummary";
import { ROUTE } from "../../../utils/apis/constant";

const steps = ["Survey information", "Design your survey", "Trigger criteria", "Response analysis"];

export const SurveyComponent = () => {
  const param = useParams();
  const surveyId = param.surveyId;
  const [activeStep, setActiveStep] = useState(0);
  const [saving, setSaving] = useState(false);
  const [completed] = useState<{
    [k: number]: boolean;
  }>({});
  const [survey, setSurvey] = useState<Survey>();

  useEffect(() => {
    const getSurveyBySurvey = async () => {
      const survey = await getSurveyBySurveyId(surveyId);
      setSurvey(survey);
    };

    getSurveyBySurvey();
  }, [surveyId, param]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleCloseSaving = () => {
    setSaving(false);
  };

  const onSurveyEnabledChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSaving(true);
    const surveyEnabled = e.target.checked as boolean;
    await updateSurvey({ ...survey, is_active: surveyEnabled });
    if (survey) {
      setSurvey({ ...survey, is_active: surveyEnabled });
    }
  };

  if (!survey) {
    return null;
  }

  return (
    <Container>
      <Box my={4}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Breadcrumbs>
            <Link underline="hover" color="inherit" href={ROUTE.DASHBOARD}>
              Dashboard
            </Link>
            <Typography>{survey.title || survey._id}</Typography>
          </Breadcrumbs>
          <Switch checked={survey?.is_active} onChange={onSurveyEnabledChange} />
        </Box>
        <Box sx={{ overflowX: "auto", overflowY: "hidden", whiteSpace: "nowrap" }}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box my={4}>
          {activeStep === 0 && <CreateNewSurveyStep initialSurvey={survey} onSaving={setSaving} />}
          {activeStep === 1 && <EditSurveyStep initialSurvey={survey} onSaving={setSaving} />}
          {activeStep === 2 && <TriggerSurvey initialSurvey={survey} onSaving={setSaving} />}
          {activeStep === 3 && <ReportSummary />}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button onClick={handleNext} sx={{ mr: 1 }}>
            Next
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={saving}
        onClose={handleCloseSaving}
        autoHideDuration={1000}
        TransitionComponent={Grow}>
        <Box textAlign="center">Saving</Box>
      </Snackbar>
    </Container>
  );
};
