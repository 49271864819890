import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Snackbar, Alert, AlertTitle, Container, Grid2 as Grid, Link } from "@mui/material";
import LoginForm from "./components/login-Form";
import SignupForm from "./components/signup-form";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { PROTERATE_REFRESH_TOKEN, PROTERATE_TOKEN, ROUTE } from "../../../utils/apis/constant";
import { postCreateUser } from "../../../utils/apis/user-api";
import { auth } from "../../../config/firebase";
import { GoogleAuthProvider, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { REDIRECT_URL } from "../../../config/internal";

export const Auth: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isSignUpSuccess, setIsSignUpSuccess] = useState(false);

  const [cookies, setCookie] = useCookies([PROTERATE_TOKEN, PROTERATE_REFRESH_TOKEN]);

  const navigator = useNavigate();

  const handleLoginSubmit = async (data: Record<string, string>) => {
    const res = await signInWithEmailAndPassword(auth, data.email, data.password);
    const userToken = await res.user.getIdToken(true);

    setCookie(PROTERATE_TOKEN, userToken);
    setCookie(PROTERATE_REFRESH_TOKEN, res.user.refreshToken);

    if (res.user.emailVerified === false) {
      await sendEmailVerification(res.user, { url: REDIRECT_URL });
      navigator(ROUTE.VERIFY_EMAIL);
    }

    navigator(ROUTE.DASHBOARD);
  };

  const handleSignupSubmit = async (data: Record<string, string>) => {
    try {
      const res = await postCreateUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        role: "admin",
        provider: "password",
      });

      if (res.status === 201 || res.status === 200) {
        const user = await signInWithEmailAndPassword(auth, data.email, data.password);

        if (!user.user.emailVerified) {
          await sendEmailVerification(user.user, { url: REDIRECT_URL });
          navigator(ROUTE.VERIFY_EMAIL);
        }
      }
    } catch (error: any) {
      console.log(error["code"]);
    }
  };

  const onGoogleLogin = async () => {
    const googleProvider = new GoogleAuthProvider();

    const googleUser = await signInWithPopup(auth, googleProvider);

    await postCreateUser({
      firstName: googleUser.user.displayName || undefined,
      lastName: undefined,
      email: googleUser.user.email || undefined,
      password: undefined,
      role: "admin",
      provider: "google.com",
      providerUserId: googleUser.user.uid,
    });

    const token = await googleUser.user.getIdToken();
    setCookie(PROTERATE_TOKEN, token);
    setCookie(PROTERATE_REFRESH_TOKEN, googleUser.user.refreshToken);
  };

  useEffect(() => {
    if (cookies.proterateToken) {
      navigator(ROUTE.DASHBOARD);
    }
  }, [cookies.proterateToken, navigator]);

  return (
    <>
      {isLoginFailed && (
        <Alert variant="filled" severity="error">
          <AlertTitle>Error</AlertTitle>
          Username or password is incorrect.
        </Alert>
      )}
      {isSignUpSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={3000}
          open={isSignUpSuccess}
          onClose={() => {
            setIsSignUpSuccess(false);
          }}
          message="Signup success"
        />
      )}

      <Container>
        <Box display="flex" alignItems="center" height="100vh" my={4}>
          <Grid container spacing={2} display="flex" flexDirection="row">
            <Grid size={{ lg: 6, md: 6, xs: 12 }} display="flex" flexDirection="column" alignItems="flex-end">
              <img src="/assets/auth-state.jpg" alt="Auth page" width="100%" />
              <Typography variant="overline" fontSize="10px" fontWeight={1}>
                <Link color="black" href="http://www.freepik.com" underline="none" target="_blank" rel="noreferrer">
                  Designed by Freepik
                </Link>
              </Typography>
            </Grid>
            <Grid size={{ lg: 6, md: 6, xs: 12 }}>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                {isLogin ? (
                  <LoginForm onSubmit={handleLoginSubmit} onGoogleLogin={onGoogleLogin} />
                ) : (
                  <SignupForm onSubmit={handleSignupSubmit} onGoogleLogin={onGoogleLogin} />
                )}

                <Box textAlign="center" mt={2}>
                  <Typography variant="body2">
                    {isLogin ? "Don't have an account?" : "Already have an account?"}
                  </Typography>
                  <Button variant="text" onClick={() => setIsLogin(!isLogin)}>
                    {isLogin ? "Sign Up" : "Login"}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
