import React, { useEffect, useState } from "react";
import { Box, Container, InputAdornment, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { Survey } from "../../../../types/survey";
import { getSurveyBySurveyId, updateSurvey } from "../../../../utils/apis/survey-api";
import { SURVEY_DEFAULT } from "../../../../utils/apis/constant";

export interface TriggerSurveyProps {
  onSaving: (isSaving: boolean) => void;
  initialSurvey: Survey;
}

const TriggerSurvey: React.FC<TriggerSurveyProps> = ({ onSaving, initialSurvey }) => {
  const [survey, setSurvey] = useState<Survey>(initialSurvey);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (touched) {
        onSaving(true);
        await updateSurvey(survey);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [onSaving, survey, touched]);

  const onSurveyHookUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const hookUrl = e.target.value;
    let trigger_criteria = survey?.trigger_criteria;
    if (trigger_criteria && trigger_criteria.length < 1) {
      trigger_criteria.push({ hookUrl: "", pattern: "exact" });
    }

    if (trigger_criteria && trigger_criteria[0]) {
      trigger_criteria[0].hookUrl = hookUrl;
    }
    setTouched(true);
    setSurvey((prev: Survey) => ({ ...prev, trigger_criteria }));
  };

  return (
    <Container>
      <Box my={2}>
        <Box my={2}>Trigger criteria</Box>
        <Box my={2}>
          <TextField
            label="url"
            placeholder="https://example.com"
            value={survey?.trigger_criteria?.[0]?.hookUrl}
            onChange={onSurveyHookUrlChange}
            fullWidth
            required
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">exact</InputAdornment>,
              },
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default TriggerSurvey;
