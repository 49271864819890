import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { IconContainerProps } from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import CloseIcon from "@mui/icons-material/Close";

import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { Survey } from "../../types/survey";
import axiosInstance from "../../utils/axios-instance";

import "./nps.scss";
import { SURVEY_DEFAULT } from "../../utils/apis/constant";

export const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

export const customIcons: {
  [index: string]: {
    icon: React.ReactElement<unknown>;
    label: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

export const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

export const NPS = () => {
  const [cookies, setCookie] = useCookies(["proterate", "proterateUUID", "proterateSurveyId"]);
  const [searchParams] = useSearchParams();
  const defaultOpen = cookies.proterate?.["responsed"] || false;
  const [open, setOpen] = useState(!defaultOpen);
  const [survey, setSurvey] = useState<Survey>(SURVEY_DEFAULT);

  const handleOnSurveyClose = useCallback(async () => {
    setOpen(false);
    window.parent.postMessage("closeSurvey", "*");
  }, []);

  const onSentimentClick = async (event: React.SyntheticEvent, value: number | null) => {
    const responseId = cookies.proterate["responseId"];
    const uid = cookies.proterateUUID;

    await axiosInstance.post("/answer", {
      surveyId: survey._id,
      uid: uid,
      questionId: survey?.question?.[0].question_id[0]._id,
      answerValue: value,
    });

    await axiosInstance.patch("/response/" + responseId, {
      responses: [{ questionId: survey?.question?.[0].question_id[0]._id, responseValue: value }],
    });

    setCookie("proterate", { responseId: responseId, responsed: true }, { path: "/" });
    window.parent.postMessage("closeSurvey", "*");
  };

  useEffect(() => {
    const fetchData = async () => {
      const workspaceId = searchParams.get("workspace_id");
      const url = window.parent.location.href;
      const res = await axiosInstance.post<Survey>("/survey/" + workspaceId, { url });
      if (!res) {
        setOpen(false);
      }
      const surveyResponse = res.data;
      if (!cookies.proterateUUID) {
        setCookie("proterateUUID", crypto.randomUUID(), { path: "/" });
      }
      if (surveyResponse._id) {
        if (!surveyResponse.is_active) {
          setOpen(false);
        }
        const surveyMode = surveyResponse.display_option === "2" ? "openSurveyWidget" : "openSurveyFullScreen";
        window.parent.postMessage(surveyMode, "*");
        setSurvey(surveyResponse);
        if (!cookies.proterate) {
          const response = await axiosInstance.post("/response", {
            surveyId: surveyResponse._id,
            uid: cookies.proterateUUID,
          });
          setCookie("proterate", { responseId: (response.data as { _id: string })["_id"] }, { path: "/" });
        }
      }
    };

    if (cookies.proterate?.["responsed"]) {
      window.parent.postMessage("closeSurvey", "*");
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (survey?.display_option === "2") {
    return (
      <Box className="nps-survey-container">
        <Card className="nps-survey-card" variant="outlined">
          <CardHeader
            subheader={survey?.question?.[0].question_id[0].question_display}
            action={
              <IconButton onClick={handleOnSurveyClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid2 spacing={0} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                getLabelText={(value: number) => customIcons[value].label}
                highlightSelectedOnly
                sx={{ gap: "8px" }}
                onChange={onSentimentClick}
                size="large"
              />
            </Grid2>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleOnSurveyClose}
        id="survey-container"
        className="survey-variant-1-desktop-container">
        <DialogTitle>{survey?.question?.[0].question_id[0].question_display}</DialogTitle>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <StyledRating
            name="highlight-selected-only"
            defaultValue={2}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value].label}
            highlightSelectedOnly
            onChange={onSentimentClick}
            sx={{ gap: "8px" }}
            size="large"
          />
        </DialogContent>
      </Dialog>

      <Box className="survey-variant-1-mobile-container">
        <Backdrop open></Backdrop>
        <Card className="nps-survey-card" variant="outlined">
          <CardHeader
            subheader={survey?.question?.[0].question_id[0].question_display}
            action={
              <IconButton onClick={handleOnSurveyClose}>
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            <Grid2 spacing={0} display="flex" flexDirection="row" alignItems="center" justifyContent="center">
              <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                getLabelText={(value: number) => customIcons[value].label}
                highlightSelectedOnly
                sx={{ gap: "8px" }}
                onChange={onSentimentClick}
                size="large"
              />
            </Grid2>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
