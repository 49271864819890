import React, { useEffect, useState } from "react";
import { Box, Card, Container, Grid2, Table, TableCell, TableRow, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { GaugeContainer, GaugeValueArc, GaugeReferenceArc, useGaugeState } from "@mui/x-charts/Gauge";
import { getReport } from "../../../../utils/apis/report-api";
import { Report } from "../../../../types/report";

interface ReportSummaryProps {}

const ReportSummary: React.FC<ReportSummaryProps> = () => {
  const [totalScore, setTotalScore] = useState(0);
  const [totalResponse, setTotalResponse] = useState(0);
  const [survey, setSurvey] = useState<Report[]>([]);
  const param = useParams();
  const surveyId = param.surveyId;

  useEffect(() => {
    const fetchDate = async () => {
      const survey = await getReport(surveyId);
      setSurvey(survey);
      const totalScore = (survey.length || 1) * 5;
      const currentScore = survey.reduce((acc, cur) => acc + Number(cur?.responses?.[0]?.response_value || 0), 0);
      setTotalScore(Math.round(currentScore * (100 / totalScore)));
      setTotalResponse(survey.length);
    };

    fetchDate();
  }, [surveyId]);

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="red" />
        <path d={`M ${cx} ${cy} L ${target.x} ${target.y}`} stroke="red" strokeWidth={3} />
      </g>
    );
  }

  return (
    <Container>
      <Box my={4}>
        <Box display="flex" justifyContent="center">
          <Box>
            <GaugeContainer width={400} height={400} startAngle={-100} endAngle={100} value={totalScore}>
              <GaugeReferenceArc />
              <GaugeValueArc />
              <GaugePointer />
            </GaugeContainer>
          </Box>
        </Box>
        <Box>
          <Grid2 container spacing={4}>
            <Grid2 size={6}>
              <Card variant="outlined">
                <Box textAlign="center" my={2}>
                  <Typography variant="h5">Total score</Typography>
                  <Typography variant="h3">{totalScore}</Typography>
                </Box>
              </Card>
            </Grid2>
            <Grid2 size={6}>
              <Card variant="outlined">
                <Box textAlign="center" my={2}>
                  <Typography variant="h5">Responses</Typography>
                  <Typography variant="h3">{totalResponse}</Typography>
                </Box>
              </Card>
            </Grid2>
          </Grid2>
        </Box>
        <Box>
          <Table>
            <TableRow>
              <TableCell>UUID</TableCell>
              <TableCell>Response value</TableCell>
            </TableRow>
            {survey.map((item) => {
              return (
                <TableRow>
                  <TableCell>{item.uid}</TableCell>
                  <TableCell>{item.responses[0].response_value}</TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Box>
      </Box>
    </Container>
  );
};

export default ReportSummary;
