import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { customIcons, IconContainer, StyledRating } from "../../../../sdk/nps/nps";
import { Survey } from "../../../../types/survey";
import { updateSurvey } from "../../../../utils/apis/survey-api";

export interface EditSurveyStepProps {
  onSaving: (isSaving: boolean) => void;
  initialSurvey: Survey;
}

const EditSurveyStep: React.FC<EditSurveyStepProps> = ({ onSaving, initialSurvey }) => {
  const [survey, setSurvey] = useState<Survey>(initialSurvey);
  const [variant, setVariant] = useState(initialSurvey.display_option || "1");
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (touched) {
        onSaving(true);
        await updateSurvey({ ...survey, display_option: variant });
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [onSaving, survey, touched, variant]);

  const onSurveyQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const questionDisplay = e.target.value;
    let question = survey.question;
    if (question && question[0] && question[0].question_id && question[0].question_id[0]) {
      question[0].question_id[0].question_display = questionDisplay;
    }
    setTouched(true);
    setSurvey((prev: Survey) => ({ ...prev, question }));
  };

  const handleVariant = (event: SelectChangeEvent) => {
    setTouched(true);
    setVariant(event.target.value as string);
  };

  return (
    <Container>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Display option</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={variant}
          label="Display option"
          onChange={handleVariant}>
          <MenuItem value="1">Center</MenuItem>
          <MenuItem value="2">Right corner</MenuItem>
        </Select>
      </FormControl>
      {variant === "1" && (
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="500px"
          sx={{ background: "rgba(0, 0, 0, 0.5)" }}>
          <Card sx={{ width: "400px", padding: "16px" }}>
            <TextField
              label="Display"
              value={survey.question?.[0]?.question_id?.[0]?.question_display}
              onChange={onSurveyQuestionChange}
              fullWidth
              required
            />
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
              <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                getLabelText={(value: number) => customIcons[value].label}
                highlightSelectedOnly
                sx={{ gap: "8px" }}
                size="large"
              />
            </DialogContent>
          </Card>
        </Box>
      )}
      {variant === "2" && (
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="500px"
          position="relative"
          sx={{ background: "rgba(0, 0, 0, 0.5)" }}>
          <Card
            sx={{ width: "100%", maxWidth: "400px", padding: "16px", position: "absolute", bottom: "0", right: "0" }}>
            <TextField
              label="Display"
              value={survey.question?.[0]?.question_id?.[0]?.question_display}
              onChange={onSurveyQuestionChange}
              fullWidth
              required
            />
            <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
              <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                getLabelText={(value: number) => customIcons[value].label}
                highlightSelectedOnly
                sx={{ gap: "8px" }}
                size="large"
              />
            </DialogContent>
          </Card>
        </Box>
      )}
    </Container>
  );
};

export default EditSurveyStep;
