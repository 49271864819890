import React from "react";
import { Box, Typography, Container, Grid2 as Grid, Card, CardContent } from "@mui/material";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const HowItWorksSection: React.FC = () => {
  return (
    <Box sx={{ py: 16 }}>
      <Container>
        <Typography variant="h3" textAlign="center" gutterBottom>
          Our services
        </Typography>
        <Grid spacing={2} container justifyContent="center" alignContent="center">
          <Grid spacing={{ xs: 12, md: 6, lg: 6 }}>
            <Card
              sx={{
                textAlign: "center",
                p: 2,
                bgcolor: "primary.main",
                color: "white",
                width: "300px",
                minHeight: "270px",
              }}>
              <CardContent>
                <Box>
                  <QueuePlayNextIcon fontSize="large" />
                </Box>
                <Typography variant="h6" fontWeight="bold" mt={2}>
                  Your input drives our innovation!
                </Typography>
                <Typography variant="body2" mt={2} fontWeight={10}>
                  Easily submit feature requests to help shape our product. Share your ideas, and let us know what
                  matters most to you
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid spacing={{ xs: 12, md: 6, lg: 6 }}>
            <Card
              sx={{
                textAlign: "center",
                p: 2,
                bgcolor: "primary.main",
                color: "white",
                width: "300px",
                minHeight: "270px",
              }}>
              <CardContent>
                <Box>
                  <SupportAgentIcon fontSize="large" />
                </Box>
                <Typography variant="h6" fontWeight="bold" mt={2}>
                  Support
                </Typography>
                <Typography variant="body2" mt={2} fontWeight={10}>
                  Get the help you need, when you need it. Our support team is here to assist with any questions or
                  issues, ensuring a smooth and hassle-free experience.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorksSection;
