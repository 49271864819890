import React from "react";
import AuthForm from "./auth-form";

const SignupForm: React.FC<{ onSubmit: (formData: Record<string, string>) => void; onGoogleLogin: () => void }> = ({
  onSubmit,
  onGoogleLogin,
}) => {
  const fields = [
    { name: "firstName", label: "First name", type: "text" },
    { name: "lastName", label: "Last name", type: "text" },
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
    { name: "confirmPassword", label: "Confirm Password", type: "password" },
  ];

  return (
    <AuthForm title="Sign Up" buttonText="Sign Up" onSubmit={onSubmit} onGoogleLogin={onGoogleLogin} fields={fields} />
  );
};

export default SignupForm;
