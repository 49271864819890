import React from "react";
import { Box, Card, CardHeader, Grid2, CardActionArea, CardContent, Switch } from "@mui/material";
import { Survey } from "../../../../types/survey";

interface SurveysProps {
  surveys: Survey[];
  onClick: (id?: string) => void;
}

const Surveys: React.FC<SurveysProps> = ({ surveys, onClick }) => {
  return (
    <Grid2 container spacing={2}>
      {surveys.map((survey) => (
        <Grid2 size={{ xs: 12, md: 12, lg: 6, xl: 4 }} key={survey._id}>
          <Card sx={{ width: "100%", minWidth: "370px", cursor: "pointer" }}>
            <CardActionArea
              onClick={() => {
                onClick(survey?._id);
              }}>
              <Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <CardHeader
                    title={survey.title || "Survey title"}
                    subheader={survey.description}
                    sx={{ minHeight: "88px", alignItems: "flex-start" }}
                  />
                  <Switch checked={survey.is_active} />
                </Box>
              </Box>
              <CardContent>{survey?.creator_id?.name}</CardContent>
            </CardActionArea>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default Surveys;
