import React from "react";
import AuthForm from "./auth-form";

const LoginForm: React.FC<{ onSubmit: (formData: Record<string, string>) => void; onGoogleLogin: () => void }> = ({
  onSubmit,
  onGoogleLogin,
}) => {
  const fields = [
    { name: "email", label: "Email", type: "email" },
    { name: "password", label: "Password", type: "password" },
  ];

  return (
    <AuthForm title="Login" buttonText="Login" onSubmit={onSubmit} onGoogleLogin={onGoogleLogin} fields={fields} />
  );
};

export default LoginForm;
