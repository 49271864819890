import { Typography, Link, Grid2 as Grid, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../../utils/apis/constant";

export const VerifyEmail = () => {
  const navigator = useNavigate();

  const handleGoBackToLogin = () => {
    navigator(ROUTE.ACCOUNT);
  };

  return (
    <>
      <Grid container spacing={2} height="100vh">
        <Grid size={{ xs: 12, md: 6, lg: 6 }} display="flex" alignItems="center" justifyContent="center">
          <Box width="400px" display="flex" flexDirection="column" alignItems="center">
            <img src="/assets/verify-email.jpg" alt="Verify email" width="100%" />
            <Typography variant="overline" fontSize="10px" fontWeight={1}>
              <Link color="black" href="http://www.freepik.com" underline="none" target="_blank" rel="noreferrer">
                Designed by Freepik
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 3, lg: 3 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Typography variant="h5" textAlign="center" mb={2}>
              Please verify your email
            </Typography>
            <Button variant="contained" onClick={handleGoBackToLogin}>
              Go back to login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
