import React, { useEffect, useState } from "react";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import Surveys from "../survey/list/SurveyList";
import { useNavigate } from "react-router-dom";

import { getUserApi } from "../../../utils/apis/user-api";
import { createSurvey, getSurveysByUserId } from "../../../utils/apis/survey-api";
import { ROUTE } from "../../../utils/apis/constant";
import { Survey } from "../../../types/survey";

const SurveyDashboard: React.FC = () => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [userId, setUserId] = useState<string>("");
  const navigator = useNavigate();

  useEffect(() => {
    const getUserData = async () => {
      const user = await getUserApi();
      setUserId(user?.data?.uid || "");
    };

    getUserData();
  }, []);

  useEffect(() => {
    const getSurveyes = async () => {
      const surveys = await getSurveysByUserId(userId);
      setSurveys(surveys);
    };
    if (userId) {
      getSurveyes();
    }
  }, [userId]);

  const createPlaceHolder = async () => {
    const survey = await createSurvey(userId);
    const redirectUrl = `${ROUTE.SURVEY}/${survey._id}`;
    navigator(redirectUrl);
  };

  const handleOnClick = async (surveyId?: string) => {
    const redirectUrl = `${ROUTE.SURVEY}/${surveyId}`;

    navigator(redirectUrl);
  };

  if (surveys.length === 0) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" my={4}>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <img src="/assets/empty-state.png" alt="empty state" width={400} />
            <Typography variant="overline" fontSize="10px" fontWeight={1}>
              <Link color="black" href="http://www.freepik.com" underline="none" target="_blank" rel="noreferrer">
                Designed by Freepik
              </Link>
            </Typography>
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={createPlaceHolder}>
              Create New Survey
            </Button>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box my={4}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <Box>
            <Button variant="contained" color="primary" onClick={createPlaceHolder}>
              Create new survey
            </Button>
          </Box>
        </Box>
        <Box mt={4}>
          <Surveys surveys={surveys} onClick={handleOnClick} key="survey-list" />
        </Box>
      </Box>
    </Container>
  );
};

export default SurveyDashboard;
