import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/container/home";
import { Auth } from "./pages/container/auth";

import SurveyDashboard from "./pages/container/dashboard";
import { SurveyComponent } from "./pages/container/survey";
import { Navbar } from "./pages/navbar";
import { NPS } from "./sdk/nps/nps";
import { Setting } from "./pages/container/settings";
import { VerifyEmail } from "./pages/container/auth/components/verify-email";
import { HowTo } from "./pages/container/how-to";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/account" element={<Auth />} />
        <Route path="/nps" element={<NPS />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route
          path="/how-to"
          element={
            <>
              <Navbar />
              <HowTo />
            </>
          }
        />
        <Route
          path="/dashboard"
          element={
            <>
              <Navbar />
              <SurveyDashboard />
            </>
          }
        />
        <Route
          path="/survey/:surveyId"
          element={
            <>
              <Navbar />
              <SurveyComponent />
            </>
          }
        />
        <Route
          path="/setting"
          element={
            <>
              <Navbar />
              <Setting />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
