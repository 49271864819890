import { Cookies } from "react-cookie";
import axiosInstance from "../axios-instance";
import { PROTERATE_TOKEN, ROUTE } from "./constant";
import { User } from "../../types/auth";
import { BaseResponse } from "../../types/base-response";

export const redirectToAccount = () => {
  window.location.href = ROUTE.ACCOUNT;
};

export const getUserApi = async (): Promise<BaseResponse<User>> => {
  const cookies = new Cookies();
  try {
    const token = cookies.get(PROTERATE_TOKEN);
    if (!token) {
      redirectToAccount();
    }
    const res = await axiosInstance.get<BaseResponse<User>>(ROUTE.USER_ME, {
      headers: { Authorization: "Bearer " + token },
    });

    const user = res.data;
    return user;
  } catch (error) {
    const err = error as any;
    if (err.status === 401) {
      cookies.remove(PROTERATE_TOKEN);
      redirectToAccount();
    }
    throw err;
  }
};

export const patchUserApi = async (updateUser: User) => {
  const cookies = new Cookies();
  const token = cookies.get(PROTERATE_TOKEN)?.["accessToken"];
  if (!token) {
    redirectToAccount();
  }
  const res = await axiosInstance.patch<User>(
    `${ROUTE.USER}/${updateUser.uid}`,
    {
      ...updateUser,
    },
    { headers: { Authorization: "Bearer " + token } }
  );
  if (res.status === 200) {
    return updateUser;
  }

  return updateUser;
};

export const postUserLogin = async (data: Record<string, string>) => {
  const cookies = new Cookies();
  const res = await axiosInstance.post<{ data: string }>("/auth/login", data);
  if (res.status === 200) {
    cookies.set(PROTERATE_TOKEN, res.data);
    return true;
  }

  return false;
};

export const postCreateUser = async (
  data: Record<string, string | boolean | number | undefined>
): Promise<BaseResponse<User>> => {
  const res = await axiosInstance.post<BaseResponse<User>>(ROUTE.USER, {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    provider: data.provider,
    providerUserId: data.providerUserId,
    role: "admin",
  });
  if (res.data.status === 201) {
    return res.data;
  }
  return res.data;
};
