import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { Survey } from "../../../../types/survey";
import { updateSurvey } from "../../../../utils/apis/survey-api";

interface CreateNewSurveyStepProps {
  onSaving: (isSaving: boolean) => void;
  initialSurvey: Survey;
}

const CreateNewSurveyStep: React.FC<CreateNewSurveyStepProps> = ({ onSaving, initialSurvey }) => {
  const [survey, setSurvey] = useState<Survey>(initialSurvey);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (touched) {
        onSaving(true);
        await updateSurvey(survey);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [onSaving, survey, touched]);

  const handleTitleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value as string;
    setTouched(true);
    setSurvey((prev: Survey) => ({ ...prev, title }));
  };

  const handleDescriptionChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const description = e.target.value as string;
    setTouched(true);
    setSurvey((prev: Survey) => ({ ...prev, description }));
  };

  return (
    <Box>
      <Box my={2}>
        <TextField label="Survey title" value={survey?.title} onChange={handleTitleChange} fullWidth required />
      </Box>
      <Box my={2}>
        <TextField
          label="Description"
          value={survey?.description}
          onChange={handleDescriptionChange}
          fullWidth
          required
          multiline
          rows={3}
        />
      </Box>
    </Box>
  );
};

export default CreateNewSurveyStep;
