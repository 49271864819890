import React from "react";
import { Box, Typography, Container, Grid2 as Grid, Card, CardContent } from "@mui/material";
import InsightsIcon from "@mui/icons-material/Insights";
import ShareIcon from "@mui/icons-material/Share";
import TuneIcon from "@mui/icons-material/Tune";

const features = [
  {
    title: "Customizable Surveys",
    description: "Design surveys to match your needs.",
    icon: <TuneIcon fontSize="large" />,
  },
  {
    title: "Analytics Tools",
    description: "Gain insights with real-time data.",
    icon: <InsightsIcon fontSize="large" />,
  },
  { title: "Easy Sharing", description: "Distribute surveys via embeds.", icon: <ShareIcon fontSize="large" /> },
];

const FeaturesSection: React.FC = () => {
  return (
    <Box
      sx={{
        bgcolor: "primary.main",
        color: "white",
        py: 16,
        textAlign: "center",
      }}>
      <Container>
        <Typography variant="h3" textAlign="center" gutterBottom>
          Features designed for you
        </Typography>
        <Typography variant="body1" fontWeight={10}>
          Create surveys effortlessly with our intuitive tools. Simply design your survey, install it seamlessly, and
          start gathering valuable insights as results roll in. It's fast, efficient, and hassle-free!
        </Typography>
        <Grid container spacing={2} alignContent="center" justifyContent="center" my={4}>
          {features.map((feature, index) => (
            <Grid spacing={{ xs: 12, md: 4, lg: 4 }} key={index}>
              <Card sx={{ textAlign: "center", p: 2, width: "300px" }}>
                <CardContent>
                  <Box>{feature.icon}</Box>
                  <Typography variant="h6" fontWeight="bold" mt={2}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" mt={2} fontWeight={10}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturesSection;
