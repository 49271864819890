import React from "react";
import { Box, Typography, Button, Container, Grid2, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeroSection: React.FC = () => {
  const navigator = useNavigate();

  const onGetStartClick = () => {
    navigator("/dashboard");
  };

  return (
    <>
      <Box position="sticky" top={0} py={2} bgcolor="white" zIndex={99}>
        <Container>
          <Box display="flex" justifyContent="space-between" position="sticky">
            <Typography
              variant="h4"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                ml: 2,
                letterSpacing: ".3rem",
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}>
              Proterate
            </Typography>
            <Button variant="contained" onClick={onGetStartClick}>
              Dashboard
            </Button>
          </Box>
        </Container>
      </Box>
      <Container>
        <Grid2 container spacing={4} my={4}>
          <Grid2
            size={{ lg: 6, md: 6, xs: 12 }}
            display="flex"
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Box>
              <Typography variant="h3" fontWeight="bold" gutterBottom>
                Refine product with
                <Typography color="secondary" variant="h3">
                  insightful feedback
                </Typography>
              </Typography>
              <Typography variant="h6" mb={3} fontWeight={10}>
                Effortlessly create, share, and analyze your product with our easy-to-use platform.
              </Typography>
              <Button variant="contained" color="secondary" size="large" onClick={onGetStartClick}>
                Get Started for Free
              </Button>
            </Box>
          </Grid2>
          <Grid2
            size={{ lg: 6, md: 6, xs: 12 }}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end">
            <img src="/assets/banner.jpg" alt="banner" width="100%" />
            <Typography variant="overline" fontSize="10px" fontWeight={1}>
              <Link color="black" href="http://www.freepik.com" underline="none" target="_blank" rel="noreferrer">
                Designed by Freepik
              </Link>
            </Typography>
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
};

export default HeroSection;
