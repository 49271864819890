import { Survey } from "../../types/survey";
import axiosInstance from "../axios-instance";
import { ROUTE } from "./constant";

export const getSurveysByUserId = async (userId: string) => {
  const res = await axiosInstance.get<Survey[]>("/survey/" + userId);
  const surveys = res.data;
  return surveys;
};

export const saveSurvey = async (survey: Survey, userId: string) => {
  const surveyRes = await axiosInstance.post<Survey>(ROUTE.SURVEY, {
    ...survey,
    creatorId: userId,
    trigger_criteria: [{ hookUrl: "", pattern: "exact" }],
  });
  if (surveyRes.status === 201) {
    await axiosInstance.post("question", {
      surveyId: surveyRes.data._id,
      questionDisplay: "What is your feeling?",
      isRequired: true,
      option: {
        optionType: "rating",
      },
      step: 1,
    });
  }

  return surveyRes.data;
};

export const createSurvey = async (userId: string) => {
  const surveyRes = await axiosInstance.post<Survey>(ROUTE.SURVEY, { creatorId: userId });
  if (surveyRes.status === 201) {
    await axiosInstance.post("question", {
      surveyId: surveyRes.data._id,
      questionDisplay: "What is your feeling?",
      isRequired: true,
      option: {
        optionType: "rating",
      },
      step: 1,
    });
  }

  return surveyRes.data;
};

export const getSurveyBySurveyId = async (surveyId?: string) => {
  const survey = await axiosInstance.get<Survey>("/survey/id/" + surveyId);
  return survey.data;
};

export const updateSurvey = async (survey: Survey) => {
  const res = await axiosInstance.patch<Survey>("/survey/" + survey._id, survey);
  if (res.status === 200) {
    return survey;
  }
  return survey;
};
